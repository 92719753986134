import consts from './TabsConsts';

const {TRANSLATOR_PROJECTS, REVIEWS_PROJECTS, LL_PROJECTS, REVIEWED_BY_QA_LL} = consts;

export interface ITab {
  value: MyProjectTypeEnum;
  label: string;
}

export enum MyProjectTypeEnum {
  DEFAULT = 'translator',
  PRIORITY = 'priority',
  REVIEWS = 'review',
  LL = 'llProjects',
  REVIEWEDBYQALL = 'translatorQaLl',
}

export const tabs: ITab[] = [
  {
    value: MyProjectTypeEnum.DEFAULT,
    label: TRANSLATOR_PROJECTS,
  },
  // {
  //   value: MyProjectTypeEnum.PRIORITY,
  //   label: PRIORITY_PROJECTS,
  // },
  {
    value: MyProjectTypeEnum.REVIEWS,
    label: REVIEWS_PROJECTS,
  },
  {
    value: MyProjectTypeEnum.LL,
    label: LL_PROJECTS,
  },
  {
    value: MyProjectTypeEnum.REVIEWEDBYQALL,
    label: REVIEWED_BY_QA_LL,
  },
];

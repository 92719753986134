import {IAvailableProjectsFiltersData} from 'pages_refactor/AvailableProjects/AvailableProjectsUtils';

export const getFilterParams = (filtersData: IAvailableProjectsFiltersData) => {
  const formattedFilters = Object.fromEntries(Object.entries(filtersData).filter(([, value]) => value));

  if (formattedFilters.projectName) {
    formattedFilters.project_name = formattedFilters.projectName;
    delete formattedFilters.projectName;
  }

  return formattedFilters;
};

import {MultipleFieldProps, SortTypes} from './MyProjectsUtils';

export default {
  START_PROJECT: '+ Start new project',
  TITLE: 'My Projects',
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_REVIEW: 'IN_REVIEW',
  DELAYED: 'DELAYED',
  DELAYED_TOOLTIP_TITLE: 'Our support team is checking the issue',
  PENDING: 'PENDING',
  HOLD: 'HOLD',
  READY: 'READY',
  CANCELLED: 'CANCELLED',
  DISPUTED: 'DISPUTED',
  PREPROCESSING: 'PREPROCESSING',
  POSTPROCESSING: 'POSTPROCESSING',
  PENDING_STATUS: 'Pending Linguist',
  DELAYED_STATUS: 'Delayed',
  IN_PROGRESS_STATUS: 'In Progress',
  HOLD_STATUS: 'On Hold',
  IN_REVIEW_STATUS: 'In Review',
  READY_STATUS: 'Translation Received',
  CANCELLED_STATUS: 'Cancelled',
  COMPLETED_STATUS: 'Completed',
  DISPUTED_STATUS: 'Disputed',
  PREPROCESSING_STATUS: 'Preprocessing',
  POSTPROCESSING_STATUS: 'Postprocessing',
  MESSAGE: 'Message translator',
  NO_PROJECT_TITLE: 'No projects here yet.',
  NO_PROJECT_MESSAGE: 'Projects will appear here once you start translating.',
  NO_PROJECTS_FOUND: 'No projects match the selected filters',
  TABLE_COLUMNS: [
    {
      title: 'Project Name',
      value: 'id' as SortTypes,
    },
    {
      title: 'Type',
      value: 'type' as SortTypes,
    },
    {
      title: 'Language pair',
      value: '',
    },
    {
      title: 'Status',
      value: 'status' as SortTypes,
    },
    {
      title: 'Expertise',
      value: 'expertise' as SortTypes,
    },
    {
      title: 'Delivery time',
      value: '',
    },
    {
      title: '',
      value: '',
    },
  ],
  FILTER_MODAL_SEARCH_BTN: 'Search',
  FILTER_MODAL_CLASSNAME: 'my-project-filter-modal',
  TAG_LIST: 'Add a tag from list...',
  CREATE_NEW_TAG: 'Create a new tag',
  TAG_MODAL_TITLE: 'Tags',
  TAG_MODAL_ACTION: 'Save',
  TAG_MODAL_CLASSNAME: 'my-project-tags-modal',
  BULK_MESSAGE_MODAL_CLASSNAME: 'my-project-bulk-message-modal',
  BULK_MESSAGE_MODAL_PLACEHOLDER:
    'Write your comment here, it will be posted to translators who work on selected projects',
  RATING_MODAL_CLASSNAME: 'my-project-rating-modal',
  PROJECT_NAME_TOOLTIP:
    'This is a real project created by BLEND to translate our website. Click to see the project page',
  LANGUAGE_PAIR_TOOLTIP: 'A separate project will be opened for each language pair',
  DUE_TOOLTIP: 'The live countdown timer shows how much time is left until this project is finished',
  DOWNLOAD_RES: 'Download Resources',
  GO_BACK: 'Go back',
  DOWNLOAD: 'Download',
  DOWNLOAD_READY: 'Download success!',
  RATING_SUCCESS: 'Thanks for your time!',
  QUALITY_TITLE: 'Translation Quality Feedback',
  RATE_TITLE: 'How did we do?',
  SUBMIT_BTN: 'Send',
  RATING_SUCCESS_DETAIL:
    'We appreciate the time you took to share your experience and insights. We look forward to doing better for you next time!',
  RATING_FAILED: 'Something went wrong , please try again',
  FEW_SECONDS: 'Your download will start in a few seconds',
  TRY_AGAIN: 'If your download does not start, please try again',
  EDITING_NOTE: 'The editing project will start after the translation project is complete',
  RATE_SUB_TITLE: 'How would you rate this translation project?',
  TEXT_AREA_TITLE: 'Have any tips or suggestions for us?',
  FEEDBACK_PH: 'Share your thoughts',
  RATE: 'Rate',
  MIN_CHARS: 'Enter at least 3 chars',
  PROJECT_NAME_PLACEHOLDER: 'Enter project name...',
  TRANSCRIPTION: 'TranscriptionProject',
  REGULAR_STATUS_COLOR: {color: 'grey080', bgc: 'grey030'},
  PREPROCESSING_STATUS_COLOR: {color: 'pink100', bgc: 'pink050'},
  POSTPROCESSING_STATUS_COLOR: {color: 'cyan110', bgc: 'cyan020'},
  IN_PROGRESS_STATUS_COLOR: {color: 'yellow100', bgc: 'yellow020'},
  READY_STATUS_COLOR: {color: 'green070', bgc: 'celery050'},
  IN_REVIEW_STATUS_COLOR: {color: 'blue100', bgc: 'blue020'},
  COMPLETED_STATUS_COLOR: {color: 'green070', bgc: 'green040'},
  DOWNLOAD_MESSAGE: 'You can download only finished projects ',
  PROJECT_NAME_ERROR: 'Title length should be at least 2 character long',
  MAX_TAG_CHARS: 'Max 20 characters',
  MAX_TAGS: 'Max 30 tags',
  REFRESH_NOTE: 'New updates on your project(s) are available! Click the "Refresh" button to see the latest changes.',
  DATA_QA_PAGE_TITLE: 'my-projects-page-title',
  DATA_QA_ESTIMATION: 'my-projects-estimation',
  DATA_QA_PROJECT_STATUS: 'my-projects-project-status',
  DATA_QA_COUNT_IN_GROUP: 'my-projects-count-in-group',
  DATA_QA_LANGUAGES_TOOLTIP: 'my-projects-languages-tooltip',
  DATA_QA_EDITING_NOTE: 'my-projects-editing-note',
  DATA_QA_MAIN_ROW: 'my-projects-main-project-row',
  DATA_QA_GROUP_CHECKBOX: 'my-projects-group-checkbox',
  DATA_QA_GROUP_NAME: 'my-projects-group-name',
  DATA_QA_GROUP_ARROW: 'my-projects-group-arrow',
  DATA_QA_PROJECT_LINK: 'my-projects-project-link',
  DATA_QA_GROUP_CREATION_DATE: 'my-projects-group-creation-date',
  DATA_QA_PROJECT_CREATION_DATE: 'my-projects-project-creation-date',
  DATA_QA_PROJECT_TYPE: 'my-projects-project-type',
  DATA_QA_GROUP_VOLUME: 'my-projects-group-volume',
  DATA_QA_GROUP_LANGUAGE_PAIR: 'my-projects-group-language-pairs',
  DATA_QA_PROJECT_EXPERTISE: 'my-projects-project-expertise',
  DATA_QA_PROJECT_CHAT: 'my-projects-project-chat',
  DATA_QA_GROUP_TAGS: 'my-projects-group-tags',
  DATA_QA_PROJECT_TAGS: 'my-projects-project-tags',
  DATA_QA_ADD_TAGS_ICON: 'my-projects-add-tags-icon',
  DATA_QA_RATING_SUCCESSFUL_MODAL: 'my-projects-rating-success',
  DATA_QA_RATING_FAILED_MODAL: 'my-projects-rating-failure',
  DATA_QA_RATING_STARS_MENU: 'my-projects-base-rating',
  DATA_QA_LOW_RATING_REASONS: 'my-projects-rating-reason-checkboxes',
  DATA_QA_RATING_FEEDBACK_FORM: 'my-projects-rating-feedback-form',
  DATA_QA_RATING_MODAL_TITLE: 'my-projects-rating-modal-title',
  DATA_QA_FILTER_BY_PROJECT_NAME: 'my-projects-filter-project-name',
  DATA_QA_FILTER_BY: 'my-projects-filter-by',
  DATA_QA_EDITING_ID: 'my-projects-editing-id',
  DATA_QA_TRANSLATION_TYPE: 'my-projects-translation-type',
  DATA_QA_EDITING_TYPE: 'my-projects-editing-type',
  DATA_QA_EDITING_STATUS: 'my-projects-editing-status',
  DATA_QA_EDITING_ROW: 'my-projects-editing-row',
  DATA_QA_SUB_ROW: 'my-projects-sub-row',
  DATA_QA_REFRESH_BUTTON: 'my-projects-refresh-button',
  DATA_QA_DOWNLOAD_BUTTON: 'my-projects-download-button',
  DATA_QA_SELECTED_PROJECTS_COUNT: 'my-projects-selected-projects-count',
  DATA_QA_PAGINATION: 'my-projects-pagination',
  DATA_QA_FILTER_LABELS: 'my-projects-filter-labels',
  DATA_QA_DELETE_FILTER_BUTTON: 'my-projects-delete-filter-button',
  BULK_MESSAGE_TOOLTIP_TEXT: 'Select at least 2 projects to send message in bulk',
};

export const downloadOptions = [
  {
    value: '',
    label: 'Download all documents',
  },
  {
    value: '?onlyTranslated=1',
    label: 'Download translated only',
  },
];

export const multipleFields: MultipleFieldProps[] = [
  {
    value: 'ids',
    label: 'ID',
    placeholder: 'Select ID...',
  },
  {
    value: 'statuses',
    label: 'Status',
    placeholder: 'Select status...',
  },
  {
    value: 'tags',
    label: 'Tags',
    placeholder: 'Select tags...',
  },
  {
    value: 'sourceLanguages',
    label: 'Source language',
    placeholder: 'Select language...',
  },
  {
    value: 'targetLanguages',
    label: 'Target language',
    placeholder: 'Select language...',
  },
];

export const badRatingReasons = [
  {
    flag: 'trans_unsatisfactory_service',
    title: 'Unsatisfactory service',
  },
  {
    flag: 'trans_overly_literal',
    title: 'Overly literal',
  },
  {
    flag: 'trans_not_followed_instrctns',
    title: 'Instructions were not followed',
  },
  {
    flag: 'trans_spell_tps_grmr_mistakes',
    title: 'Grammar mistakes or typos',
  },
  {
    flag: 'trans_delivery_not_on_time',
    title: 'Delivery was not on time',
  },
];

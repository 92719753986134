export default {
  BANNER_TITLE: 'Find the Perfect Voice for Your Project',
  BANNER_DESCRIPTION:
    'Better voice actors, more languages—BLEND connects you with a global network of professional voice-over artists to improve your engagements on any technology, media or application.',
  GET_STARTED: 'Get Started',
  FEATURED: 'Featured',
  FEMALE_VOICE_ENGLISH: 'Female voice | English',
  MALE_VOICE_ENGLISH: 'Male voice | English',
  EXPLORE: 'Explore',
  LANGUAGE: 'Language',
  PROJECT_TYPE: 'Project type',
  GENDER: 'Voice type',
  AGE: 'Age',
  FILTER: 'Filter',
  SORT: 'Sort',
  CLEAR_ALL: 'Clear All',
  DONE: 'Done',
  PERFORMANCE: 'Performance',
  CLEAR_FILTERS: 'Clear all Filters',
  SORT_BY: 'Sort by',
  LISTEN_MORE: 'Listen More',
  HIDE: 'Hide',
  FILL_FORM: 'The Perfect Sound Is Just a Form Away!',
  SEND_REQUEST: 'Send Request',
  REQUEST_SENT_SUCCESS: 'Thanks! Request sent successfully',
  YOUR_FIRST_NAME: 'Your First Name',
  YOUR_LAST_NAME: 'Your Last Name',
  YOUR_EMAIL: 'Your Email',
  YOUR_COMPANY_NAME: 'Your Company Name',
  SIGN_UP_VOICE: 'Sign Up as a Voice Talent',
  TELL_US_PROJECT_ABOUT: 'Tell us about your project',
  ARTIST_WITHOUT_AUDIO: "This artist hasn't uploaded any audio yet",
  CONTENT_TOOLTIP:
    'Your voice search begins here! Use the left sidebar to filter by language, project type, style, and more. Once you’ve found the perfect voice, click the “Get Started” button on the right side of the audio sample to start your project.',
  SINGLE_OPTION: ['gender', 'voiceAges', 'performance', 'sortBy', 'sortDirection', 'language'],
  SIGN_UP_LINK: 'https://8c2l0ugidj4.typeform.com/to/LCrMeZNE',
};

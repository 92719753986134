import ReactGA from 'react-ga4';
import {
  IFilterOption,
  ISelectedFilters,
  ILanguageFilterDto,
  IFilters,
  SelectedFiltersNameEnumKeysType,
  ISelectedFiltersType,
} from 'store/pages/voicesStore';
import Consts from './VoicesConsts';

const {YOUR_FIRST_NAME, YOUR_LAST_NAME, YOUR_EMAIL, YOUR_COMPANY_NAME} = Consts;

export enum TalentSortEnum {
  NAME = 'NAME',
  FEATURED = 'FEATURED',
  CREATED = 'CREATED',
}

export enum SortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const sortingOptions: IFilterOption[] = [
  {
    value: TalentSortEnum.FEATURED,
    label: 'Most popular',
  },
  {
    value: TalentSortEnum.CREATED,
    label: 'Date created',
  },
  {
    value: TalentSortEnum.NAME,
    label: 'Name',
  },
];

export const DEFAULT_FILTERS: ISelectedFilters = {
  language: '174360014',
  projectTypes: ['all'],
  sortBy: TalentSortEnum.FEATURED,
  sortDirection: SortDirectionEnum.DESC,
};

export const userSkills: string[] = ['gender', 'language', 'voiceAges', 'projectTypes'];

const allFilter: IFilterOption = {
  value: 'all',
  label: 'All',
};

export const filtersDto = (data: ILanguageFilterDto[]) => {
  const filtersData: IFilters = data.reduce(
    (prev: any, {type, value, label}: ILanguageFilterDto) => ({
      ...prev,
      [type]: prev?.[type]?.concat({value, label}) || [{value, label}],
    }),
    {}
  );

  return {
    ...filtersData,
    projectTypes: [allFilter].concat(filtersData?.projectTypes || []),
    voiceAges: [allFilter].concat(filtersData?.voiceAges || []),
    gender: [allFilter].concat(filtersData?.gender || []),
  };
};

export const checkDefaultFiltersPresent = (filters: ISelectedFilters) => {
  Object.keys(DEFAULT_FILTERS).forEach((key: string) => {
    if (!filters?.[key as SelectedFiltersNameEnumKeysType]) {
      (filters as any)[key] = DEFAULT_FILTERS[key as SelectedFiltersNameEnumKeysType] || [];
    }
  });
  return filters;
};

export interface IFormData {
  firstname?: string;
  lastname?: string;
  email?: string;
  company?: string;
  message?: string;
}

export const hiddenFieldsForForm: (keyof ISelectedFiltersType)[] = [
  'projectTypes',
  'language',
  'gender',
  'voiceAges',
  'performance',
];

export type IFormDataType = IFormData;

// covering all existing types of emails addresses excluding special symbols
export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export interface IFormField {
  field: keyof IFormDataType;
  label: string;
}

export const formFields: IFormField[] = [
  {
    field: 'firstname',
    label: YOUR_FIRST_NAME,
  },
  {
    field: 'lastname',
    label: YOUR_LAST_NAME,
  },
  {
    field: 'email',
    label: YOUR_EMAIL,
  },
  {
    field: 'company',
    label: YOUR_COMPANY_NAME,
  },
];

const secondsPerMinute = 60;

export const convertAsTwoNumberDigits = (value: number | string) => `0${value}`.slice(-2);

export const getAudioTime = (secondsDuration: number) =>
  convertAsTwoNumberDigits(Math.floor(secondsDuration / secondsPerMinute)) +
  '.' +
  convertAsTwoNumberDigits(secondsDuration % secondsPerMinute);

export const getRandomAudioPeaks = () => new Array(50).fill(null).map(() => Math.random() * (100 - 10) + 10);

export enum GaCategoryEnum {
  BANNER = 'Banner',
  FILTERS = 'Filters',
  SAMPLE_CARD = 'Sample card',
  HUBSPOT_FORM = 'Hubspot form',
}

export const postGaEvent = (category: GaCategoryEnum, action: string, label?: string) => {
  ReactGA.event(category, {category, action, label});
};

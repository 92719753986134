import {OptionsProps} from 'components/BaseComponents/BaseRadio';
import consts from './PaymentQuoteViewConsts';
import {TargetLanguage} from 'pages/Wizard/types';

export enum QouteTypeEnum {
  WORD_COUNT = 'WORD_COUNT',
  PURCHASE_CREDITS = 'PURCHASE_CREDITS',
}

export const quoteTypesList: OptionsProps[] = [
  {value: QouteTypeEnum.WORD_COUNT, label: consts.WORD_COUNT},
  {value: QouteTypeEnum.PURCHASE_CREDITS, label: consts.PURCHASE_CREDITS},
];

export const getDataForRechargeAmountQuote = (CSRFToken: string, rechargeAmount: string, name: string): FormData => {
  const fd = new FormData();
  fd.append('CSRFToken', CSRFToken);
  fd.append('sum', rechargeAmount);
  fd.append('type', 'budget');
  fd.append('name', name);

  return fd;
};

export enum IModalVariantEnum {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface IVoucherModalState {
  isOpen: boolean;
  variant: IModalVariantEnum;
}

export interface IValidateFormDataItem {
  type: 'number' | 'string' | 'array' | 'boolean';
  required: boolean;
  value: number | string | Object[] | boolean;
}

export interface IValidateFormDataConfig {
  [fieldName: string]: IValidateFormDataItem;
}

export const validateFormData = (config: IValidateFormDataConfig): string[] => {
  const keys: string[] = Object.keys(config);

  const errorsList = keys.reduce((array: string[], keyName: string) => {
    const item: IValidateFormDataItem = config[keyName];
    if (item.type === 'number') {
      const isError = item.required ? !item.value : false;
      return isError ? [...array, keyName] : array;
    }

    if (item.type === 'string') {
      const isError = item.required ? !item.value : false;
      return isError ? [...array, keyName] : array;
    }

    if (item.type === 'array' && Array.isArray(item.value)) {
      const isError = item.required ? !item.value.length : false;
      return isError ? [...array, keyName] : array;
    }

    return array;
  }, []);

  return errorsList;
};

export const getDataForWordCountQuote = (
  CSRFToken: string,
  name: string,
  sourceLanguage: string,
  targetLanguages: TargetLanguage[] | string[],
  wordCount: number,
  needProofreading: boolean,
  expertise?: string,
  billingInfo?: string
): FormData => {
  const fd = new FormData();
  fd.append('CSRFToken', CSRFToken);
  fd.append('type', 'project');
  fd.append('name', name);
  fd.append('source_language', sourceLanguage);
  targetLanguages.forEach((lang: TargetLanguage | string) =>
    fd.append('target_languages[]', typeof lang === 'string' ? lang : lang.slug)
  );
  fd.append('expertise', expertise || '');
  fd.append('wordCount', String(wordCount));
  fd.append('needProofreading', String(needProofreading));
  fd.append('billingInfo', billingInfo || '');

  return fd;
};

const onlyDigitsOrEmptyStringRegExp = /^\d*$/;

export const checkValueIsDigitsOnly = (value: string) => onlyDigitsOrEmptyStringRegExp.test(value);

export interface IQuoteData {
  payment_information: IQuoteDataPaymentInfo;
  price_quote: IQuoteDataPriceQuote;
}

export interface IQuoteDataPaymentInfo {
  orderID: number | boolean;
  translationItems: IQuoteDataTranslationItem[];
  words: IQuoteDataWords;
  paymentItems: IQuoteDataPaymentItems;
  needProofReading: boolean;
}

export enum TranslationItemNameEnum {
  Budget = 'Budget',
  Credits = 'Credits',
}

export interface IQuoteDataPrice {
  credits: number;
  localized: number;
}

export interface IQuoteDataTranslationItem {
  name: TranslationItemNameEnum;
  price: IQuoteDataPrice;
  srcLang?: IQuoteTranslationItemLang;
  tarLang?: IQuoteTranslationItemLang;
  expertiseName: string | null;
  separatePrices?: IQuoteTranslationItemSeparatePrice;
  wordCount: number;
  needProofreading: boolean;
}

export interface IQuoteTranslationItemSeparatePrice {
  translation: IQuoteDataCost;
  editing: IQuoteDataCost;
}

export interface IQuoteDataWords {
  general: number;
  expertise: number;
}

export interface IQuoteDataPaymentItems {
  currency: string;
  currencyExchange: number;
  cost: IQuoteDataCost;
  VAT: IQuoteDataPaymentItemAmountData;
  totalIncludingVat: IQuoteDataPaymentItemAmountData;
  totalCoupon?: IQuoteDataCost;
  discount: IQuoteDataPaymentItemAmountData;
  totalNoVat: IQuoteDataPaymentItemAmountData;
  voucherPromotion?: IQuoteDataPaymentItemAmountData;
  usedFromBalance?: IQuoteDataPaymentItemAmountData;
  debt?: IQuoteDataPaymentItemAmountData;
  transactionFee?: IQuoteDataPaymentItemAmountData;
  restMinPayment?: IQuoteDataPaymentItemAmountData;
}

export interface IQuoteDataPaymentItemAmountData {
  localized: number;
}

export interface IQuoteDataCost {
  localized: number;
  credits: number;
}

export interface IQuoteDataPriceQuote {
  SUMMARY_COST: IQuoteDataSummaryCost;
  SUMMARY_TOTAL_VAT: IQuoteDataSummaryCost;
}

export interface IQuoteDataSummaryCost {
  credits?: number;
  customer: number;
  std: number;
}

export interface IQuoteTranslationItemLang {
  name: string;
  langID: number;
  slug: string;
  langCode: string;
}

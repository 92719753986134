import {IBonusProject, IBundleProject, IClassicProject, ILLProject, IQAProject} from './AvailableProjectsUtils';

export const checkIsNewProjectAvailable = (
  oldProjects: (IClassicProject | ILLProject | IBonusProject | IQAProject | IBundleProject)[],
  newProjects: (IClassicProject | ILLProject | IBonusProject | IQAProject | IBundleProject)[]
): boolean => newProjects.some((proj) => !oldProjects.find((oldProj) => oldProj?.project_id === proj?.project_id));

export const sortClassicProjects = (projects?: IClassicProject[]): IClassicProject[] => {
  const newProjectsList = projects ? [...projects] : [];
  newProjectsList.sort((prev, next) => {
    const sortValue = prev.expedite ? -1 : 1;
    return prev.expedite === next.expedite ? 0 : sortValue;
  });

  return newProjectsList;
};

import {OptionsProps} from 'components/BaseComponents/BaseAutocomplete';
import dayjs from 'dayjs';
import {IAvailableProjectsFiltersData} from 'pages_refactor/AvailableProjects/AvailableProjectsUtils';
import {FilterLabelsProps} from 'pages_refactor/MyProjects/MyProjectsUtils';

const dayFormat = 'MMM DD, YYYY';

export enum DateOptionEnum {
  WEEK = 'week',
  MONTH = 'month',
  HALF_YEAR = 'halfYear',
  YEAR = 'year',
  ALL = 'all',
  CUSTOM = 'custom',
}

export const dateOptions: OptionsProps[] = [
  {value: DateOptionEnum.WEEK, label: 'This Week'},
  {value: DateOptionEnum.MONTH, label: 'This Month'},
  {value: DateOptionEnum.HALF_YEAR, label: 'Six Months'},
  {value: DateOptionEnum.YEAR, label: 'This Year'},
  {value: DateOptionEnum.ALL, label: 'All Time'},
  {value: DateOptionEnum.CUSTOM, label: 'Custom'},
];

export const dateOptionsNames: string[] = dateOptions.map(({value}) => String(value));

export enum MyProjectStatusEnum {
  ALL = 'ALL',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  READY = 'READY',
}

export const statusOptions: OptionsProps[] = [
  {value: MyProjectStatusEnum.ALL, label: 'All'},
  {value: MyProjectStatusEnum.CANCELLED, label: 'Cancelled'},
  {value: MyProjectStatusEnum.COMPLETED, label: 'Completed'},
  {value: MyProjectStatusEnum.IN_PROGRESS, label: 'In Progress'},
  {value: MyProjectStatusEnum.PENDING, label: 'Pending Linguist'},
  {value: MyProjectStatusEnum.READY, label: 'Translation Received'},
];

export interface IMyProjectsFiltersData {
  date: DateOptionEnum;
  filterByStatus: MyProjectStatusEnum;
  client?: string;
  filterByDateFrom?: number;
  filterByDateTo?: number;
  projectName?: string;
}

export enum IMyProjectsFiltersDataEnum {
  date,
  filterByStatus,
  client,
  filterByDateFrom,
  filterByDateTo,
  projectName,
}

export enum IFilterName {
  date = 'Date',
  filterByStatus = 'Status',
  client = 'Client',
  filterByDateFrom = 'from',
  filterByDateTo = 'to',
  projectName = 'Project Name',
}

export const DEFAULT_FILTERS_DATA: IMyProjectsFiltersData = {
  date: DateOptionEnum.HALF_YEAR,
  client: '',
  filterByStatus: MyProjectStatusEnum.ALL,
};

const timezoneOffset: number = new Date().getTimezoneOffset() * 60;

export const getFilterParams = (filtersData: IMyProjectsFiltersData) => {
  const formattedFilters = Object.fromEntries(Object.entries(filtersData).filter(([, value]) => value));

  const dateTo: number = Math.floor(new Date().getTime() / 1000 - timezoneOffset);
  switch (formattedFilters.date as DateOptionEnum) {
    case DateOptionEnum.ALL:
      formattedFilters.filterByDateFrom = 10000;
      formattedFilters.filterByDateTo = dateTo;
      break;
    case DateOptionEnum.HALF_YEAR:
      formattedFilters.filterByDateFrom = Math.floor(dayjs().subtract(6, 'month').unix().valueOf());
      break;
    case DateOptionEnum.WEEK:
      formattedFilters.filterByDateFrom = dayjs().subtract(7, 'days').unix().valueOf() - timezoneOffset;
      formattedFilters.filterByDateTo = dateTo;
      break;
    case DateOptionEnum.MONTH:
      formattedFilters.filterByDateFrom = dayjs().subtract(1, 'month').unix().valueOf() - timezoneOffset;
      formattedFilters.filterByDateTo = dateTo;
      break;
    case DateOptionEnum.YEAR:
      formattedFilters.filterByDateFrom = dayjs().subtract(1, 'year').unix().valueOf() - timezoneOffset;
      formattedFilters.filterByDateTo = dateTo;
      break;
    case DateOptionEnum.CUSTOM:
      formattedFilters.filterByDateFrom = filtersData.filterByDateFrom;
      formattedFilters.filterByDateTo = filtersData.filterByDateTo;
      break;
    default:
      break;
  }
  delete formattedFilters.date;

  if (formattedFilters.status === MyProjectStatusEnum.ALL) {
    delete formattedFilters.status;
  }

  if (formattedFilters.projectName) {
    formattedFilters.project_name = formattedFilters.projectName;
    delete formattedFilters.projectName;
  }

  return formattedFilters;
};

export const getStartUnixDate = (date: Date, dateEnd?: boolean) => {
  date.setHours(dateEnd ? 23 : 0);
  date.setMinutes(dateEnd ? 59 : 0);
  date.setSeconds(0 - timezoneOffset);

  return Math.floor(date.getTime() / 1000);
};

export const getFiltersDisplay = (
  filtersData: IMyProjectsFiltersData | IAvailableProjectsFiltersData
): FilterLabelsProps[] => {
  const keyValueArray: FilterLabelsProps[] = Object.entries(filtersData)
    .filter(([, value]) => !!value)
    .reduce((array: FilterLabelsProps[], [key, value]) => {
      const label: string = key in IFilterName ? IFilterName[key as keyof typeof IFilterName] : key;

      if (key in DEFAULT_FILTERS_DATA && DEFAULT_FILTERS_DATA[key as keyof IMyProjectsFiltersData] === value) {
        return array;
      }

      switch (key) {
        case 'filterByStatus':
          const obj = statusOptions.find(({value: objValue}) => value === objValue);
          return [
            ...array,
            {
              label,
              value: [obj?.label || value],
              state_value: key,
            },
          ];
        case 'date':
          const dateObj = dateOptions.find(({value: objValue}) => objValue === value);
          const dateLabel =
            dateObj?.value === DateOptionEnum.CUSTOM &&
            'filterByDateFrom' in filtersData &&
            'filterByDateTo' in filtersData
              ? `${
                  filtersData.filterByDateFrom
                    ? `from ${dayjs(new Date(filtersData.filterByDateFrom * 1000)).format(dayFormat)}`
                    : ''
                } ${
                  filtersData.filterByDateTo
                    ? ` to ${dayjs(new Date(filtersData.filterByDateTo * 1000)).format(dayFormat)}`
                    : ''
                }`
              : dateObj?.label;
          return [
            ...array,
            {
              label,
              value: [dateLabel],
              state_value: key,
            },
          ];
        case 'filterByDateFrom':
        case 'filterByDateTo':
          return array;
        default:
          return [
            ...array,
            {
              label,
              value: [value],
              state_value: key,
            },
          ];
      }
    }, []);

  return keyValueArray;
};

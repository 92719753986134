import api from 'api';
import {useQuery} from 'react-query';
import {QUERY_KEYS} from 'utils/const';
import {FeatureProps} from '../WizardUtils';
import {UiFeatures} from '../types';

export default function useUiFeatures() {
  const response = useQuery(QUERY_KEYS.UI_FEATURE, api.wizard.getUiFeatures, {
    staleTime: Infinity,
  });

  const uiFeatures: FeatureProps = {};
  const enabledFeatures = response?.data?.results?.filter((item) => item.status === 1);
  enabledFeatures?.forEach((feature: UiFeatures) => (uiFeatures[feature.featureDefinitionUuid] = feature));

  return {uiFeatures};
}

import consts from './TabsConsts';

const {CLASSIC_PROJECTS, BUNDLE_PROJECTS, BONUS_PROJECTS, QA_PROJECTS, LL_PROJECTS} = consts;

export interface ITab {
  value: AvailableProjectTypeEnum;
  label: string;
}

export enum AvailableProjectTypeEnum {
  CLASSIC = 'classic-projects',
  BUNDLE = 'bundle-projects',
  BONUS = 'bonus-projects',
  QA = 'qa-projects',
  LL = 'll-projects',
  RATING = 'rating-projects',
}

export const tabs: ITab[] = [
  {
    value: AvailableProjectTypeEnum.CLASSIC,
    label: CLASSIC_PROJECTS,
  },
  {
    value: AvailableProjectTypeEnum.BUNDLE,
    label: BUNDLE_PROJECTS,
  },
  {
    value: AvailableProjectTypeEnum.BONUS,
    label: BONUS_PROJECTS,
  },
  {
    value: AvailableProjectTypeEnum.QA,
    label: QA_PROJECTS,
  },
  {
    value: AvailableProjectTypeEnum.LL,
    label: LL_PROJECTS,
  },
];
